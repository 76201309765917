import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage } from 'gatsby-plugin-image'
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import * as styles from './group.module.css'
import Bounce from 'react-reveal/Bounce';
import Reveal from 'react-reveal/Reveal';
import "./swipe.css";
import { renderRichText } from 'gatsby-source-contentful/rich-text'
// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";

const GroupSection = ({ musiciens }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <div style={{height: '70px', textAlign: 'center'}}>
        <h2>LA FORMATION</h2>
      </div>
      <Swiper
        onActiveIndexChange={(slide) => setActiveIndex(slide.activeIndex)}
        style={{
          "--swiper-navigation-color": "#000",
          "--swiper-pagination-color": "#000",
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        { musiciens.map((musicien, i) => (
          <SwiperSlide style={{ backgroundColor: musicien.backgroundColor }} key={i}>
            <li key={musicien.name} className={styles.article}>
              <div className='article-content'>
              {musicien.heroImage &&
                <Bounce left style={{flex: 1}} when={activeIndex === i}>
                   <GatsbyImage style={{borderRadius: "50%", width: 300, height: 300}} alt="" tyle={{flex: 1}} 
                    image={musicien.heroImage.gatsbyImageData} />
                </Bounce> 
              }
              </div>
              <div className='article-content'>
                <Bounce right opposite when={activeIndex === i}>
                  <h2 className={styles.title}>{musicien.name}</h2>
                </Bounce>
                <Reveal effect="animate__animated animate__fadeInUp" 
                        when={activeIndex === i}
                        effectOut="animate__animated animate__fadeOutDown">
                  <div>
                    {musicien.shortBio?.raw && renderRichText(musicien.shortBio)}
                  </div>
                </Reveal>
              </div>
            </li>
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        // slidesPerView={5}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        { musiciens.map((musicien, i) => (
          musicien.thumb &&
          <SwiperSlide style={{ backgroundColor: musicien.backgroundColor }} key={i}>
            <GatsbyImage alt={musicien.name} image={musicien.thumb? musicien.thumb.gatsbyImageData : musicien.heroImage.gatsbyImageData} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default GroupSection;