import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage } from 'gatsby-plugin-image'
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "./swipe.css";
// import required modules
import { FreeMode, Navigation } from "swiper";

const CarouselSection = ({ carousel }) => {

  return (
    <>
      <div style={{height: '70px', textAlign: 'center'}}>
        <h2>GALERIE</h2>
      </div>
      <Swiper
        style={{
          "--swiper-navigation-color": "#264653",
          "--swiper-pagination-color": "#264653",
        }}
        spaceBetween={10}
        navigation={true}
        loop={true}
        modules={[FreeMode, Navigation]}
        className="mySwiper2"
      >
        { carousel.map((slide, i) => (
          <SwiperSlide key={i}>
            <img    src={slide.gatsbyImageData.images.fallback.src}
                    style={{height: "100%", width: 'auto'}} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default CarouselSection;